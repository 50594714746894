import {
  setIsBurgerActive,
  setPageLoaded,
  setPopup,
  setVideoPopupState,
} from "../../features/Global/Global.slice";

import Store from "../../Store";

export default class GlobalService {
  setIsBurgerActive(value: boolean) {
    Store.dispatch(setIsBurgerActive(value));
  }

  setVideoPopupState(payload: boolean) {
    Store.dispatch(setVideoPopupState(payload));
  }

  setPopup(payload: boolean) {
    Store.dispatch(setPopup(payload));
  }

  setPageLoaded(payload: boolean) {
    Store.dispatch(setPageLoaded(payload));
  }
}
