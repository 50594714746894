import {
  Box,
  Container,
  Heading,
  Image,
} from "../../components/StyledComponent";
import FramerContainer from "../../components/StyledComponent/FramerContainer";
import Socials from "../../components/SocialIcons/Socials";
import { motion } from "framer-motion";
import {
  scaleIn,
  slideInGlobal,
  slideInGlobal2,
  slideInGlobal3,
  slideInGlobal4,
} from "../../components/FramerMotion/FramerMotion";
import Motion from "../../components/StyledComponent/Motion";
import { useLocation, useNavigate } from "react-router-dom";
import { ClientDataProps, data } from "./data";
import { useEffect, useState } from "react";
import Menu from "./Menu";
import styled from "styled-components";

const MenuContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1231;
`;

const SingleClient = () => {
  const { pathname } = useLocation();
  const [client, setClient] = useState<ClientDataProps | null>(null);

  useEffect(() => {
    setClient(data.filter((item) => item.to === pathname)[0]);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("content");
      element?.scrollIntoView({ behavior: "smooth" });
    }, 1400);
  }, [client]);

  if (!client) return <></>;

  return (
    <FramerContainer enableFooter>
      <MenuContainer>
        <Menu className="h-full" />
      </MenuContainer>
      <Container id="content" className="pb-0 relative z-0">
        {/* <select
            className="py-2 px-5"
            onChange={(e) => {
              handleClick(e.currentTarget.value);
            }}
          >
            {data.map((item, i) => (
              <option key={i} value={item.to} selected={item.to === client.to}>
                {item.name}
              </option>
            ))}
          </select> */}
        <Box
          className={`${
            client.imageLeft
              ? "flex-col lg:flex-row "
              : "flex-col lg:flex-row-reverse"
          } flex  w-full justify-center items-center text-white`}
        >
          <Box className="w-full">
            <Image src={client.img} className="w-3/4 mx-auto md:w-full" />
          </Box>
          <Box className="w-full lg:pr-[2vh] mt-10 md:mt-0">
            <Motion className="w-1/4 bg-primary h-2" {...slideInGlobal} />
            <Motion {...slideInGlobal2}>
              <Heading className="font-bold mt-10">{client.name}</Heading>
            </Motion>
            <motion.div className="mt-10" {...slideInGlobal3}>
              <Box dangerouslySetInnerHTML={{ __html: client.bio }} />
            </motion.div>
            <Motion className="mt-16" {...slideInGlobal4}>
              <Socials data={client.social} />
            </Motion>
            {/* <Motion className="mt-10 text-hover opacity-30">
                <Link to={RoutePattern.Clients}>
                  <FontAwesomeIcon icon={faLeftLong as any} className="mr-5" />
                  <Span className="italic">Back</Span>
                </Link>
              </Motion> */}
          </Box>
        </Box>
      </Container>
    </FramerContainer>
  );
};

export default SingleClient;
