import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  fadeInGlobal4,
  fadeInGlobal5,
} from "../../components/FramerMotion/FramerMotion";
import { Fluid, Text } from "../../components/StyledComponent";
import Motion from "../../components/StyledComponent/Motion";
import { data } from "./data";
import BG_IMG from "../../assets/images/client-bg.png";

interface MenuProps {
  height?: string;
  className?: string;
  style?: any;
}

const Menu = ({ height, className, style }: MenuProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [location, setLocation] = useState(pathname);
  const [currentMenuActive, setCurrentMenuActive] = useState<string>(pathname);

  useEffect(() => {
    setTimeout(() => {
      setLocation(pathname);
    }, 700);
  }, []);

  const menuItems = data.map((item, idx) => (
    <Link
      key={idx}
      to={item.to} // route
      className={`${
        currentMenuActive === item.to ? "nav-active" : "nav"
      } client text-shadow uppercase font-bold text-shadow text-lg text-gray-400 md:text-3xl hover:text-primary`}
      onClick={() => {
        setIsActive(false);
      }}
      onMouseOver={() => {
        setCurrentMenuActive(item.to);
      }}
      onMouseLeave={() => {
        setCurrentMenuActive(pathname);
      }}
    >
      {item.name}
    </Link>
  ));

  return (
    <Fluid
      className={`text-lg w-full ${className}`}
      height={height}
      style={style}
    >
      <Motion {...fadeInGlobal4} className="text-center">
        <Text className="lg:text-3xl">CLIENTS</Text>
      </Motion>
      <Motion
        {...fadeInGlobal5}
        className="flex flex-col mt-5 justify-center items-center"
      >
        {menuItems}
      </Motion>
    </Fluid>
  );
};

export default Menu;
