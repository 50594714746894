import { Box, Container, Image, Text } from "../StyledComponent";
import LOGO_IMG from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { RoutePattern } from "../../routes/RoutePattern";
import Newsletter from "../Newsletter/Newsletter";
import { motion } from "framer-motion";
import {
  fadeInGlobal,
  fadeInGlobal2,
  fadeInGlobal3,
  fadeInGlobal4,
  fadeInGlobal5,
  globalDelay,
  slideUpDown,
} from "../FramerMotion/FramerMotion";
import { data } from "../Navigation/data";
import { useEffect, useState } from "react";

export default function Footer() {
  const { pathname } = useLocation();
  const [display, setDisplay] = useState<string>("none");

  useEffect(() => {
    if (pathname === RoutePattern.Home) {
      setTimeout(() => {
        setDisplay("none");
      }, 1200);
    } else {
      setDisplay("block");
    }
  }, [pathname]);

  return (
    <Container
      id="footer"
      className="text-lg mx-auto bg-black z-10"
      style={{
        display,
      }}
    >
      <Box className="flex flex-col justify-center items-center md:flex-row md:justify-between w-full text-primary font-bold py-10">
        <motion.div {...fadeInGlobal} className="mt-5 md:mt-0">
          <Image className="w-16" src={LOGO_IMG} alt="logo" />
        </motion.div>
        <motion.div
          {...fadeInGlobal2}
          className="flex-col mt-5 text-center md:text-left md:mt-0 hidden md:flex"
        >
          {data.map((item, i) => (
            <Link key={i} className="uppercase hover:text-hover" to={item.to}>
              {item.first && item.name}
            </Link>
          ))}
        </motion.div>
        <Box className="flex flex-row flex-nowrap w-full md:w-auto">
          <motion.div
            {...fadeInGlobal2}
            className="w-full flex flex-col mt-5 text-center md:text-left md:mt-0 md:hidden"
          >
            {data.map((item, i) => (
              <Link key={i} className="uppercase hover:text-hover" to={item.to}>
                {item.first && item.name}
              </Link>
            ))}
          </motion.div>
          <motion.div
            {...fadeInGlobal3}
            className="w-full flex flex-col mt-5 text-center md:text-left md:mt-0"
          >
            {data.map((item, i) => (
              <Link key={i} className="uppercase hover:text-hover" to={item.to}>
                {!item.first && item.name}
              </Link>
            ))}
          </motion.div>
        </Box>
        <motion.div {...fadeInGlobal4} className="mt-5 md:mt-0">
          <Newsletter />
        </motion.div>
      </Box>
      <motion.div {...fadeInGlobal5} className="text-center w-full py-2">
        <Text className="text-white text-center">&copy; Legion</Text>
      </motion.div>
    </Container>
  );
}
