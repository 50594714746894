import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: "home",
    to: "/",
    isRoute: true,
    first: true,
  },
  {
    name: "about",
    to: "/" + RoutePattern.About,
    isRoute: true,
    first: true,
  },
  {
    name: "clients",
    to: "/" + RoutePattern.Clients,
    isRoute: true,
    first: true,
  },
  {
    name: "shop",
    to: "/" + RoutePattern.Shop,
    isRoute: true,
  },
  {
    name: "contact",
    to: "/" + RoutePattern.Contact,
    isRoute: true,
  },
];
