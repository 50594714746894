import useScrollTop from "../../app/Hooks/useScrollTop";
import { motion } from "framer-motion";
import { fadeInOut } from "../FramerMotion/FramerMotion";
import { HTMLAttributes } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";

type FramerContainerProps = HTMLAttributes<HTMLDivElement> & {
  enableFooter?: boolean;
};

const FramerContainer = ({ enableFooter, ...props }: FramerContainerProps) => {
  // useScrollTop();

  return (
    <>
      <motion.div initial {...fadeInOut} className="min-h-screen flex flex-col">
        <Navigation />
        {props.children}
        {enableFooter && <Footer />}
      </motion.div>
    </>
  );
};

export default FramerContainer;
