import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import JACKROCHON from "../../assets/images/jack-rochon.png";
import LUKALA from "../../assets/images/lu-kala.webp";
import NIJACHARLES from "../../assets/images/nija-charles.png";
import SERGIOROMERO from "../../assets/images/sergio-romero.png";
// import HOOPS from "../../assets/images/";
import VONWATERS from "../../assets/images/von-waters.webp";
import KDDO from "../../assets/images/kddogram.webp";
import { SocialTypes } from "../../components/SocialIcons/data";

export interface ClientDataProps {
  to: string;
  isRoute: boolean;
  img: string;
  name: string;
  bio: string;
  imageLeft: boolean;
  social: SocialTypes[];
}

export const data: ClientDataProps[] = [
  {
    name: "Jack Rochon",
    to: "/client/jack-rochon",
    isRoute: true,
    img: JACKROCHON,
    bio: `Jack Rochon is an LA-based multi- instrumentalist and producer capable of 
performing successfully in any genre. His most recent works are with H.E.R, Daniel 
Caesar, 6lack, French Montana, DaBaby, Spinall, Kiana Ledé, Capella Grey, 
Jacquees, Charlotte Day Wilson, etc. Sessions with Selena Gomez, Normani, 
TyDollaSign and he is also a go-to for A level writers, Nija Charles and Ant Clemons.`,
    imageLeft: false,
    social: [
      {
        icon: faInstagram,
        link: "https://www.instagram.com/jackrochon/",
      },
    ],
  },
  {
    name: `Lu Kala`,
    to: "/client/lu-kala",
    isRoute: true,
    img: LUKALA,
    bio: `
    LU KALA is a fiery voiced, multi-talented singer and songwriter with an extraordinary ability to deliver on various song concepts and create anthemic choruses. With her international background, she excels at writing global sounding toplines. LU KALA was featured on Latto’s chart topping “Lottery” record and has collaborated with songwriters & producers such as Kbeazy, Dr.Luke, Nija Charles, Jesse Shatkin, John Ryan, Theron Thomas, Rollo, Tim Suby, Futuristics, Lowell, Lauren Larue, and more.
    `,
    imageLeft: false,
    social: [
      {
        icon: faYoutube,
        link: "https://www.youtube.com/watch?v=3cNTE4E4QZg",
      },
      {
        icon: faInstagram,
        link: "https://www.instagram.com/igobylu/",
      },
    ],
  },
  {
    name: "Nija Charles",
    to: "/client/nija-charles",
    isRoute: true,
    img: NIJACHARLES,
    bio: `Prolific songwriter and record producer, Nija Charles has already contributed 
towards 12 Grammy nominations, two number 1's on Billboard Hot 100 and has also
landed a spot on Forbes "30 under 30" list. Her work with artists such as Beyonce, 
Jay Z, Ariana Grande, Meg Thee Stallion, Drake, Lady Gaga, Kehlani, Post Malone, 
Meek Mill, Travis Scott, 21 Savage, Chris Brown and many more, has secured her 
position as a creative powerhouse and tastemaker within culture.`,
    imageLeft: false,
    social: [
      {
        icon: faYoutube,
        link: "https://www.youtube.com/watch?v=2ZC0Wq2IFes&ab_channel=NijaVEVO",
      },
      {
        icon: faInstagram,
        link: "https://www.instagram.com/amnija_/",
      },
    ],
  },
  {
    name: "Serg Dior",
    to: "/client/serg-dior",
    isRoute: true,
    img: SERGIOROMERO,
    bio: `Sergio is a multi-talented 100%-er producer based in Chicago signed to Nija Charles 
and Legion. Though his R&B melodic style is his specialty, Sergio has a mixing pot 
of influence and has depth across many genres. Recent collaborators include Doja 
Cat, Tory Lanez, Dess Dior, RealestK, Iggy Azalea, Ant Clemons, Nija, Blxst, PNB 
Rock, and more.`,
    imageLeft: false,
    social: [
      {
        icon: faInstagram,
        link: "https://www.instagram.com/1sergior/",
      },
    ],
  },
  {
    name: "Hoops",
    to: "/client/hoops",
    isRoute: true,
    img: "",
    bio: `
    Hoops is a NY based multi-instrumentalist and producer, known for his melodies. 
Hoops has notably produced “TOPIA TWINS '' for Travis Scott, “Rush” for Ayra Starr, 
and “Heyy” for Lil Baby. Along with producing more records for Lil Baby, Lil Durk, 
NBA Youngboy, 21 Savage, NoCap, MoneyMan, Fredo Bang, Remar, Tyla Yaweh and 
more. Recent collaborators include MikeWillMadeIt, Tay Keith, Smash David, 
JetsonMade, PoohBeatz, OG Parker, TurnMeUpYC, Wheezy, Nick Papz and is a 
frequent collaborator with chart topping songwriter Nija Charles.
    `,
    imageLeft: false,
    social: [
      {
        icon: faInstagram,
        link: "https://www.instagram.com/prodhoops/",
      },
    ],
  },
  {
    name: "Von Waters",
    to: "/client/von-waters",
    isRoute: true,
    img: VONWATERS,
    bio: `
      Based in Atlanta, Georgia, Von Waters is an emerging artist/songwriter, and 
engineer whose strengths are clever concepts, lyrics, and memorable melodies. 
Waters has had recent cuts/placements with Normani, Kiana Lede, Chris Brown, Trey
Songz, Tamar Braxton, Kcamp, YK Osiris and more. Recent collaborators include 
Section 8, ChiChi, LondonOnDaTrack, Jonah Christian, Chrishan, KDDO, Hitmaka, 
Romano, OG Parker, Nick Papz, and more.
    `,
    imageLeft: false,
    social: [
      {
        icon: faInstagram,
        link: "https://www.instagram.com/trevonsmusic",
      },
    ],
  },
  {
    name: "kddo",
    to: "/client/kddo",
    isRoute: true,
    img: KDDO,
    bio: `Based in Los Angeles, but born in Nigeria, Born in Nigeria, KDDO is one of the top 
writers in music working with names such as Chris Brown, Partynextdoor, Rich The 
Kid, Davido, Summer Walker, Diddy, WizKid, Ari Lennox, Becky G, Shenseea, Stefflon
Don and many others. As a true 100%-er, he both wrote and produced the recent 
chart topping hit "Under the Influence '' by Chris Brown, in its entirety and has been 
doing more in the space since!`,
    imageLeft: false,
    social: [
      {
        icon: faInstagram,
        link: "https://www.instagram.com/kddogram",
      },
    ],
  },
];
