import { ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  bg?: boolean;
  fixed?: boolean;
  position?: 'fixed' | 'bg' | 'parallax';
};

const Image = ({ bg, position = 'bg', ...props }: ImageProps) => {
  switch (position) {
    case 'fixed':
      return <img className={'fixed top-0 left-0 w-full h-screen object-cover -z-10'} {...props} />;

    case 'bg':
      return (
        <img className={'absolute top-0 left-0 w-full h-full object-cover -z-10'} {...props} />
      );

    default:
      return <img className="object-cover w-full h-full" {...props} />;
  }
};

export default Image;
