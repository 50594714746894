import { Fluid } from "../../components/StyledComponent";
import Socials from "../../components/SocialIcons/Socials";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { fadeInGlobal2 } from "../../components/FramerMotion/FramerMotion";
import { motion } from "framer-motion";
import FramerContainer from "../../components/StyledComponent/FramerContainer";
import { useLocation } from "react-router-dom";
import { RoutePattern } from "../../routes/RoutePattern";
import useGlobalService from "../Hooks/useGlobalService";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

import DESKTOPMP4 from "../../assets/videos/3-spins.desktop.mp4";
import MOBILEMP4 from "../../assets/videos/3-spins.mobile.mp4";
import MOBILEGIF from "../../assets/videos/mobile.gif";

export default function Home() {
  const { pathname } = useLocation();
  const { setIsBurgerActive } = useGlobalService();
  const { pageLoaded } = useSelector((state: RootState) => state.Global);
  const autoplayVideo: any = useRef();

  console.log("pulled");

  // handle autoplay
  useEffect(() => {
    if (pageLoaded) {
      const target = autoplayVideo.current as HTMLVideoElement;
      const src = window.innerWidth >= 768 ? DESKTOPMP4 : MOBILEMP4;

      target.src = src;
      target.play();

      // if target is paused, replace it with an image
      if (target.paused) {
        const d = document.createElement("img");

        d.src = MOBILEGIF;
        d.className = "w-full h-full object-cover";

        target.parentNode!.replaceChild(d, target);
      }
    }
  }, [autoplayVideo, pageLoaded]);

  return (
    <FramerContainer>
      <Fluid
        className="text-white w-full flex flex-col justify-center items-center h-screen overflow-hidden cursor-pointer"
        onClick={() => {
          if (pathname === RoutePattern.Home) {
            setIsBurgerActive(true);
          }
        }}
      >
        <video
          ref={autoplayVideo}
          id="autoplay-video"
          playsInline
          muted
          className="w-full h-full object-cover fixed top-0 left-0 md:object-contain md:relative transition-all"
          onTimeUpdate={(e) => {
            const target = e.currentTarget;
            // const currentTime = target.currentTime;

            if (target.currentTime >= 14) {
              target.currentTime = 0;
            }
          }}
        ></video>
        <motion.div
          {...fadeInGlobal2}
          className="fixed bottom-10 md:absolute md:bottom-10 right-10 z-50"
        >
          <Socials
            data={[
              {
                icon: faInstagram,
                link: "https://www.instagram.com/thisislegion/",
              },
            ]}
          />
        </motion.div>
      </Fluid>
    </FramerContainer>
  );
}
