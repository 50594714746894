import { ButtonHTMLAttributes, HtmlHTMLAttributes } from "react";
import useGlobalService from "../../app/Hooks/useGlobalService";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  level?: "primary";
  filler?: boolean;
  className?: string;
};

const Button = ({ level, className, ...props }: ButtonProps) => {
  const { setPopup } = useGlobalService();

  switch (level) {
    case "primary":
      return (
        <button
          className={`border font-avenir-book border-primary bg-primary rounded-full py-3 px-10 md:py-3 md:px-16 uppercase whitespace-nowrap text-xs md:text-md hover:bg-primary hover:shadow-2xl hover:shadow-primary transition-shadow ${className}`}
          {...props}
          onClick={() => setPopup(true)}
        >
          {props.children}
        </button>
      );

    default:
      return (
        <button
          className={`border border-secondary bg-secondary py-3 px-10 md:py-3 md:px-16 uppercase whitespace-nowrap text-xs md:text-md hover:bg-secondary hover:shadow-2xl hover:shadow-secondary outline-none focus:outline-none transition-shadow ${className}`}
          {...props}
        >
          {props.children}
        </button>
      );
  }
};

export default Button;
