import { MotionProps, Variants } from "framer-motion";

export const easing = [0.175, 0.25, 0.32, 0.46];
export const globalDelay = 0;

export const slideToLeftThenFadeOut: Variants = {};

export const zoomInThenFadeOut: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: "easeInOut",
      duration: 1.2,
      delay: 1.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      ease: "easeIn",
      duration: 0.4,
    },
  },
};

export const longZoomIn: MotionProps = {
  initial: {
    opacity: 0,
    scale: 1.3,
  },
  animate: {
    opacity: 1,
    scale: 1,
    zoom: "100%",
    transition: {
      ease: "easeInOut",
      duration: 4,
      delay: 0.4,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      ease: "easeIn",
      duration: 0.4,
    },
  },
};

export const scaleIn: MotionProps = {
  initial: {
    opacity: 0,
    scale: 1.3,
  },
  animate: {
    opacity: 1,
    scale: 1,
    zoom: "100%",
    transition: {
      ease: "easeInOut",
      duration: 1.2,
    },
  },
  exit: {
    opacity: 0,
    scale: 1.2,
    transition: {
      ease: "easeIn",
      duration: 0.4,
    },
  },
};

export const fadeInOut: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: "easeInOut",
      duration: 1.2,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: "easeIn",
      duration: 0.4,
    },
  },
};

export const slideFromLeftToRight: Variants = {
  initial: {
    opacity: 0,
    x: -100,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      ease: "easeInOut",
      duration: 1.2,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      type: "spring",
      duration: 1.2,
    },
  },
};

export const slideFromRightToLeft: Variants = {
  initial: {
    opacity: 0,
    x: 100,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      ease: "easeInOut",
      duration: 1.2,
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    x: -100,
    transition: {
      type: "spring",
      duration: 1.2,
    },
  },
};

export const slideUpDown: MotionProps = {
  initial: {
    opacity: 0,
    y: -100,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.2,
      delay: 1.4,
    },
  },
  exit: {
    opacity: 0,
    y: -100,
  },
};

export const fadeInGlobal: MotionProps = {
  initial: {
    opacity: 0,
    y: -50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const fadeInGlobal2: MotionProps = {
  initial: {
    opacity: 0,
    y: -50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 0.3,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const fadeInGlobal3: MotionProps = {
  initial: {
    opacity: 0,
    y: -50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 0.6,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const fadeInGlobal4: MotionProps = {
  initial: {
    opacity: 0,
    y: -50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 0.9,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const fadeInGlobal5: MotionProps = {
  initial: {
    opacity: 0,
    y: -50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 1.2,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const slideInGlobal: MotionProps = {
  initial: {
    opacity: 0,
    x: -50,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const slideInGlobal2: MotionProps = {
  initial: {
    opacity: 0,
    x: -50,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 0.3,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const slideInGlobal3: MotionProps = {
  initial: {
    opacity: 0,
    x: -50,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 0.6,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const slideInGlobal4: MotionProps = {
  initial: {
    opacity: 0,
    x: -50,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      duration: 1.4,
      delay: globalDelay + 0.9,
    },
  },
  exit: {
    opacity: 1,
  },
};
