import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isBurgerActive: false,
  isLoaded: false,
  popup: false,
  pageLoaded: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setVideoPopupState: (state, { payload }) => {
      state.isLoaded = payload;
    },
    setPopup: (state, { payload }) => {
      state.popup = payload;
    },
    setPageLoaded: (state, { payload }) => {
      state.pageLoaded = payload;
    },
  },
});

export const {
  setIsBurgerActive,
  setVideoPopupState,
  setPopup,
  setPageLoaded,
} = globalSlice.actions;
