import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  About,
  Clients,
  Contact,
  Home,
  Shop,
  SingleClient,
  Submissions,
} from "../app/index";
import PageNotFound from "../app/PageNotFound/PageNotFound";
import { RoutePattern } from "./RoutePattern";

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path={RoutePattern.Home} element={<Home />} />
        <Route path={"/" + RoutePattern.About} element={<About />} />
        <Route path={"/" + RoutePattern.Clients} element={<Clients />} />
        <Route
          path={"/" + RoutePattern.SingleClient}
          element={<SingleClient />}
        />
        <Route path={"/" + RoutePattern.Shop} element={<Shop />} />
        <Route
          path={"/" + RoutePattern.Submissions}
          element={<Submissions />}
        />
        <Route path={"/" + RoutePattern.Contact} element={<Contact />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
