import {
  Anchor,
  Box,
  Fluid,
  Heading,
  Text,
} from "../../components/StyledComponent";
import FramerContainer from "../../components/StyledComponent/FramerContainer";
import BGIMG from "../../assets/images/contact-bg.png";
import { ContactForm } from "../../components";

const Contact = () => {
  return (
    <FramerContainer enableFooter>
      <Fluid type="default" src={BGIMG}>
        <Heading className="font-bold text-white">Contact Us</Heading>
        <Box className="mt-5 text-center">
          <Text className="block">MONDAY-FRIDAY</Text>
          <Text className="block mt-5">9:00AM-5:00PM PST</Text>
        </Box>
        <ContactForm />
        <Text className="mt-5 text-gray-400 text-center">
          For submissions, please email{" "}
          <Anchor className="text-primary">info@thisislegion.co</Anchor>
        </Text>
      </Fluid>
    </FramerContainer>
  );
};

export default Contact;
