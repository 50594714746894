import { Container, Fluid, Text } from "../../components/StyledComponent";
import BG_IMG from "../../assets/images/about-bg.png";
import { motion } from "framer-motion";
import {
  fadeInGlobal4,
  fadeInGlobal5,
} from "../../components/FramerMotion/FramerMotion";
import useScrollTop from "../Hooks/useScrollTop";
import FramerContainer from "../../components/StyledComponent/FramerContainer";

const About = () => {
  useScrollTop();

  return (
    <FramerContainer enableFooter>
      <Fluid className="text-lg" height="60vh" type="default" src={BG_IMG}>
        <Container className="text-center hidden md:block">
          <motion.div {...fadeInGlobal4}>
            <Text type="primary" className="text-primary">
              LEGION{" "}
            </Text>
            <Text className="text-white">
              is a full-service creative brand with management
              <br /> and publishing divisions. Founded in 2017, by Christian
              McCurdy,
              <br /> houses some of the most prolific songwriters and producers
              <br />
              within the music industry. By prioritizing development and
              quality,
              <br />
              the company has maintained a roster in which every signee
              <br /> sees success at the highest level.
            </Text>
          </motion.div>
        </Container>
        <Container className="text-center block md:hidden">
          <motion.div {...fadeInGlobal5}>
            <Text type="primary" className="text-primary">
              LEGION{" "}
            </Text>
            <Text className="text-white">
              is a full-service creative brand with management and publishing
              divisions. Founded in 2017, by Christian McCurdy, Legion houses
              some of the most prolific songwriters and producers within the
              music industry. By prioritizing development and quality, the
              company has maintained a roster in which every signee sees success
              at the highest level.
            </Text>
          </motion.div>
        </Container>
      </Fluid>
    </FramerContainer>
  );
};

export default About;
