import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import ContactController from "../../api/Controller/ContactController";
import SubscriptionController, {
  error,
  sucess,
} from "../../api/Controller/SubcriptionController";
import useGlobalService from "../../app/Hooks/useGlobalService";
import { RootState } from "../../Store";
import { Box, Anchor, Button, Text } from "../StyledComponent";
import { InputField } from "../StyledComponent/InputField";

const Subscribe = () => {
  const { setPopup } = useGlobalService();

  return (
    <Formik
      initialValues={{ email: "", name: "", message: "" }}
      onSubmit={async (values) => {
        try {
          const controller = ContactController();
          const submitted = await controller.submitForm({
            email: values.email,
            name: values.name,
            message: values.message,
          });

          if (submitted) {
            Swal.fire({ icon: "success", text: "success" });
          } else {
            Swal.fire({ icon: "error", titleText: "Error!" });
          }
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {({ handleChange, values }) => (
        <Form className="flex flex-col justify-center items-center p-5 w-5/6 md:w-3/4 lg:w-1/2 mx-auto">
          <Box className="flex flex-row justify-between w-full mt-10">
            <InputField
              className="w-full mr-5 block"
              type="text"
              name="name"
              placeholder="Name"
            />
            <InputField
              className="w-full block"
              type="text"
              name="email"
              placeholder="E-mail"
            />
          </Box>
          <Box className="w-full bg-transparent text-primary border-2 border-primary mt-5">
            <textarea
              name="message"
              placeholder="Message"
              rows={10}
              onChange={handleChange}
              className="w-full bg-transparent p-5 outline-none focus:outline-none"
            />
          </Box>
          <Button className="w-full block mt-5">Submit</Button>
        </Form>
      )}
    </Formik>
  );
};

export default Subscribe;
