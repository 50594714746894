import { Form, Formik } from "formik";
import { Box, Text } from "../StyledComponent";
import { InputField } from "../StyledComponent/InputField";
import styled from "styled-components";
import { primary } from "../../constants";
import SubscriptionController from "../../api/Controller/SubcriptionController";
import Swal from "sweetalert2";

const Button = styled.button`
  background-color: ${primary};
`;

const Newsletter = () => {
  return (
    <Box>
      <Text className="text-primary uppercase font-bold">Newsletter</Text>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={async (values) => {
          try {
            const controller = SubscriptionController();
            let submitted = await controller.submitForm({
              email: values.email,
            });

            if (submitted) {
              Swal.fire({ icon: "success", text: "success" });
            } else {
              Swal.fire({ icon: "error", titleText: "Error!" });
            }
          } catch (error) {
            Swal.fire({ icon: "error", titleText: "Error!" });
            console.error(error);
          }
        }}
      >
        {({ handleChange }) => (
          <Form className="flex flex-row justify-center items-center">
            <input
              className="py-2 px-2 bg-transparent border-2 border-primary w-full outline-none focus:outline-none"
              type="text"
              name="email"
              onChange={handleChange}
            />
            <Button
              className="py-2 px-2 text-black border-2 border-primary font-bold"
              type="submit"
            >
              SUBSCRIBE
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Newsletter;
