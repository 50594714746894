import { data } from "./data";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import { RoutePattern } from "../../routes/RoutePattern";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import {
  fadeInGlobal,
  fadeInGlobal2,
  slideToLeftThenFadeOut,
  slideUpDown,
} from "../FramerMotion/FramerMotion";
import LOGO_IMG from "../../assets/images/logo.png";
import { Anchor, Text } from "../StyledComponent";
import useGlobalService from "../../app/Hooks/useGlobalService";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";

const Hidden = styled(motion.div)``;

const Container = styled.div`
  z-index: 1231231;
`;

export default function Navigation() {
  const { pathname } = useLocation();
  const [currentMenuActive, setCurrentMenuActive] = useState<string>(pathname);
  const [location, setLocation] = useState(pathname);
  const { setIsBurgerActive } = useGlobalService();
  const isActive = useSelector(
    (state: RootState) => state.Global.isBurgerActive
  );

  useEffect(() => {
    setTimeout(() => {
      setLocation(pathname);
    }, 700);
  }, []);

  const handleClick = () => {
    setIsBurgerActive(!isActive);
  };

  const menuItems = data.map((item, idx) =>
    // this statement is application only if we're at home
    !item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        activeClass="text-primary"
        className="mr-2 md:mr-5 text-shadow cursor-pointer text-shadow text-xs md:text-sm"
      >
        {item.name}
      </ScrollLink>
    ) : (
      <Link
        key={idx}
        to={item.to} // route
        className={`${
          currentMenuActive === item.to ? "nav-active" : "nav"
        } text-shadow uppercase font-bold text-shadow text-gray-400 text-3xl md:text-5xl hover:text-primary`}
        onClick={() => {
          setIsBurgerActive(false);
        }}
        onMouseOver={() => {
          setCurrentMenuActive(item.to);
        }}
        onMouseLeave={() => {
          setCurrentMenuActive(pathname);
        }}
      >
        {item.name}
      </Link>
    )
  );

  return (
    <Container
      className={`mx-auto flex flex-col justify-center items-center text-white bg-black py-5 px-10 ${
        location === RoutePattern.Home ? "fixed" : "relative"
      } top-0 left-0 w-full`}
    >
      <motion.div className="w-full flex justify-between items-center pt-5">
        {location === RoutePattern.Home ? (
          <div className="mx-auto text-sm md:text-2xl cursor-pointer"></div>
        ) : (
          <Link
            to={RoutePattern.Home}
            className="mr-auto md:mx-auto text-sm md:text-2xl cursor-pointer"
          >
            <motion.img
              {...fadeInGlobal2}
              src={LOGO_IMG}
              alt=""
              className="h-16 md:h-32"
            />
          </Link>
        )}
        {/* tablet navigation */}
        <Hidden
          className="uppercase w-full justify-center items-center flex-col fixed top-0 flex bg-black bg-opacity-70 h-full px-10 z-0"
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          variants={{
            hidden: {
              opacity: 0,
              right: "-100%",
              transition: {
                type: "spring",
                duration: 1,
              },
            },
            visible: {
              opacity: 1,
              right: "0%",
              transition: {
                type: "spring",
                duration: 0.75,
              },
            },
          }}
        >
          {menuItems}
        </Hidden>
        <motion.div
          className="flex flex-col z-50 cursor-pointer"
          aria-label="button"
          onClick={handleClick}
          {...fadeInGlobal}
        >
          <div
            className={`w-6 h-0.5 md:h-1 bg-primary rounded-sm ${
              isActive ? "rotate-45" : "rotate-0"
            } transition-all`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-primary rounded-sm mt-1 ${
              isActive ? "hidden" : "block"
            }`}
          />
          <div
            className={`w-6 h-0.5 md:h-1 bg-primary rounded-sm ${
              isActive ? "-rotate-45 mt-0 absolute" : "rotate-0 mt-1"
            } transition-all`}
          />
        </motion.div>
      </motion.div>
    </Container>
  );
}
