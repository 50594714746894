import { HTMLAttributes } from "react";
import styled from "styled-components";
import { ios } from "../../helpers";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import { motion } from "framer-motion";
import { longZoomIn, zoomInThenFadeOut } from "../FramerMotion/FramerMotion";

interface DefaultType {
  src?: string;
  height?: string;
}

interface ParallaxType {
  src?: string;
  height?: string;
  mobileBg?: string;
}

const Parallax = styled.div<ParallaxType>`
  height: ${(props) => props.height};
  background: url("${(props) => props.mobileBg}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: ${ios ? "static" : "fixed"};
  width: 100%;

  @media (min-width: 768px) {
    background: url("${(props) => props.src}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 80vh;
  }
`;

const Default = styled.div<DefaultType>`
  height: ${(props) => props.height};
  background: url("${(props) => props.src}");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`;

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  motion?: boolean;
  className?: string;
  type?: "parallax" | "default";
  src?: string;
  height?: string;
  mobileBg?: string;
};

const Fluid = ({
  className,
  type,
  src,
  height,
  mobileBg,
  ...props
}: ContainerProps) => {
  switch (type) {
    case "parallax":
      return (
        <Parallax
          src={src}
          mobileBg={mobileBg}
          height={height}
          className={`mx-auto justify-center items-center flex py-16 flex-col relative ${className}`}
          {...props}
        >
          {props.children}
        </Parallax>
      );
      break;

    case "default":
      return (
        <Default
          height={height}
          className={`flex-col flex justify-center items-center w-full py-16 relative ${className}`}
          {...props}
        >
          <motion.img
            {...longZoomIn}
            src={src}
            className="absolute top-0 left-0 w-full h-full object-cover -z-10"
          />
          {props.children}
        </Default>
      );

    default:
      return (
        <div
          className={`flex-col flex justify-center items-center w-full py-16 md:py-24 relative ${className}`}
          {...props}
        >
          {props.children}
        </div>
      );
  }
};

export default Fluid;
