import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  name: string;
  label?: string;
};

export const InputField: React.FC<InputFieldProps> = ({
  className,
  label,
  size: _,
  ...props
}) => {
  const [field] = useField(props);

  return (
    <div className="text-primary w-full mr-5 last:mr-0">
      {label && <label htmlFor={field.name}>{label}</label>}
      <input
        className={`border-2 border-primary py-2 px-5 bg-transparent ${className}`}
        {...field}
        {...props}
        id={field.name}
      />
    </div>
  );
};
