import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Box } from "../StyledComponent";
import { SocialTypes } from "./data";

interface SocialsItemProps {
  data: SocialTypes[];
}

const Socials = ({ data }: SocialsItemProps) => {
  return (
    <Box className="flex flex-row">
      {data.map((item, i) => (
        <Anchor
          key={i}
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className="mr-5 last:mr-0"
        >
          <FontAwesomeIcon
            icon={item.icon}
            className="text-primary hover:text-hover text-3xl cursor-pointer"
          />
        </Anchor>
      ))}
    </Box>
  );
};

export default Socials;
