import { Box } from "../../components/StyledComponent";
import FramerContainer from "../../components/StyledComponent/FramerContainer";
import Menu from "./Menu";

const Clients = () => {
  return (
    <FramerContainer enableFooter>
      <Box
        className="flex justify-center items-center"
        style={{ height: "60vh" }}
      >
        <Menu />
      </Box>
    </FramerContainer>
  );
};

export default Clients;
