import { AnchorHTMLAttributes } from 'react';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  blank?: boolean;
};

const Anchor = ({ ...props }: AnchorProps) => {
  return (
    <a className="hover:text-secondary hover:underline" rel="noreferrer" target="_blank" {...props}>
      {props.children}
    </a>
  );
};

export default Anchor;
