import { HTMLAttributes } from "react";

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  motion?: boolean;
  className?: string;
};

const Container = ({ className, ...props }: ContainerProps) => {
  return (
    <div className={`w-5/6 mx-auto relative md:w-4/5 ${className} `} {...props}>
      {props.children}
    </div>
  );
};

export default Container;
