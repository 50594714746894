import $ from "jquery";
import Swal from "sweetalert2";

export interface SubscriptionControllerProps {
  email: string;
}

export default function SubscriptionController() {
  async function _submitForm(value: SubscriptionControllerProps) {
    const endpoint =
      "https://script.google.com/macros/s/AKfycbz76yfc9gBTMcLE5nffo69jvjJoM5appJw84DKbbPMN65HTAxCPyxuuESIt6wBNefaBmQ/exec";

    if (
      !value.email ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value.email)
    ) {
      return false;
    }

    const submitted = await $.ajax(endpoint, {
      data: {
        Date: getCurrentDate(),
        IP: "::1",
        Email: value.email,
      },
    });

    return submitted.result === "success";
  }

  function getCurrentDate() {
    const date = new Date().toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    });
    const time = new Date().toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
    });
    return `${date} - ${time} (GMT-4)`;
  }

  async function getUsersIp() {
    try {
      let ip: string = await $.get(
        "https://api.ipify.org/?format=json",
        (data: any) => {
          ip = data.ip;
        }
      );
      return ip;
    } catch (error) {
      return "Error IP";
    }
  }

  return {
    submitForm(values: SubscriptionControllerProps) {
      return _submitForm(values);
    },
  };
}

export function sucess() {
  console.log("success");
  Swal.fire({
    icon: "success",
    titleText: "Success",
  });
}

export function error() {
  console.log("error");
  Swal.fire({
    icon: "error",
    titleText: "Error!",
  });
}
