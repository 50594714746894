import { Fluid, Heading } from "../../components/StyledComponent";
import FramerContainer from "../../components/StyledComponent/FramerContainer";

const Submissions = () => {
  return (
    <FramerContainer enableFooter>
      <Fluid className="bg-white">
        <Heading>Submissions</Heading>
      </Fluid>
    </FramerContainer>
  );
};

export default Submissions;
