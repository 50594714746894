import { useEffect, useState } from "react";
import useGlobalService from "../../app/Hooks/useGlobalService";

const Preloader = () => {
  const [loaded, setIsLoaded] = useState(false);
  const { setPageLoaded } = useGlobalService();
  useEffect(() => {
    window.onload = () => {
      // scroll top to 0
      window.scrollTo({ top: 0 });
      setIsLoaded(document.readyState === "complete");
      setPageLoaded(document.readyState === "complete"); // set redux state
    };
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-black"
      style={{ zIndex: !loaded ? 123123123 : -123123123 }}
    />
  );
};

export default Preloader;
