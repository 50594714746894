import styled from "styled-components";

import { useEffect } from "react";
import VideoFallback from "./VideoFallback";

const Container = styled.div`
  position: absolute;
  z-index: -99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

interface AutoplayVideoProps {
  mobile: string[];
  desktop: string[];
  gif: string;
}

export default function AutoplayVideo({
  mobile,
  desktop,
  gif,
}: AutoplayVideoProps) {
  useEffect(() => {
    const videoFallback = new VideoFallback(
      document.getElementById("autoplay-mobile-video") as any,
      gif
    );

    videoFallback.render();
  }, []);

  return (
    <>
      <Container>
        <video
          id="autoplay-desktop-video"
          preload="metadata"
          playsInline
          muted
          loop
          autoPlay
          className="w-full h-full object-cover hidden md:block relative z-20 transition-all"
        >
          {desktop.map((item, i) => (
            <source key={i} src={item} type="video/mp4" />
          ))}
        </video>
        <video
          id="autoplay-mobile-video"
          preload="metadata"
          playsInline
          autoPlay
          muted
          loop
          className="w-full h-full object-cover block md:hidden relative z-20"
        >
          {mobile.map((item, i) => (
            <source key={i} src={item} type="video/mp4" />
          ))}
        </video>
      </Container>
      <div className="fixed top-0 left-0 w-full h-full  -z-10" />
    </>
  );
}
