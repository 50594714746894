import { HtmlHTMLAttributes } from "react";

type TextProps = HtmlHTMLAttributes<HTMLHeadingElement> & {
  type?: "primary";
  className?: string;
};

const Text = ({ type, className, ...props }: TextProps) => {
  switch (type) {
    case "primary":
      return (
        <span
          className={`text-primary uppercase text-md md:text-xl ${className}`}
          {...props}
        >
          {props.children}
        </span>
      );
    default:
      return (
        <span className={`text-white text-lg ${className}`} {...props}>
          {props.children}
        </span>
      );
  }
};

export default Text;
