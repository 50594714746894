import { Box, Container, Image, Text } from "../../components/StyledComponent";
import FramerContainer from "../../components/StyledComponent/FramerContainer";
import HERO_IMG from "../../assets/images/shop-bg.png";
import { data } from "./data";
import Span from "../../components/StyledComponent/Span";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RoutePattern } from "../../routes/RoutePattern";

const Shop = () => {
  useEffect(() => {
    (window as any).ShowProducts();
  }, []);

  return (
    <FramerContainer enableFooter>
      <Box className="bg-white py-0 w-full">
        <Image className="w-full object-cover" alt="shop-bg" src={HERO_IMG} />
        <Container className="text-black text-center py-10">
          <Text className="text-black lg:text-2xl font-bold text-center">
            SHOP
          </Text>
          <Box>
            <div id="collection-component-1649430991343"></div>
          </Box>
          {/* <Box className="grid grid-cols-2 items-center w-full">
            {data.map((item, i) => (
              <Box
                className="flex flex-col justify-center items-center mt-10 w-full lg:w-1/2 p-5 mx-auto"
                key={i}
              >
                <Image
                  src={item.img}
                  alt={item.name}
                  className="w-full transform transition-all hover:scale-110"
                />
                <Span className="mt-5 text-primary font-bold uppercase md:text-xl">
                  {item.name}
                </Span>
                <Span className="text-primary md:text-xl">
                  {item.range ? "from $" + item.range : " $" + item.price}
                </Span>
              </Box>
            ))}
          </Box> */}
        </Container>
      </Box>
    </FramerContainer>
  );
};

export default Shop;
